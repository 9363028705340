<template>
	<div class="container-fluid h-100 my-2">
		<div class="row justify-content-center align-items-center h-100">
			<div class="col-auto">
				<div class="box" id="login">
					<div class="text-center"><groomy-logo/></div>
					<p class="text-center mb-4 mt-4">{{ $t('global.rapprochement_paiement_description') }}</p>
					<form @submit.prevent="submitLogs">
						<div v-if="error_message" class="alert alert-warning">{{ error_message }}</div>
						<div class="form-group">
							<label>{{ $t('compte.email') }}</label>
							<input type="email" class="form-control" placeholder="Votre adresse e-mail" id="mail_user" v-model="email">
						</div>
						<button id="button_submit" type="submit" title="Connexion" class="btn btn-primary rounded-pill btn-block mt-4 mb-3" :class="{disable: chargement}">
							{{ $t('global.rapprocher') }}
						</button>

						<div v-if="chargement">
							<p class="text-center"><font-awesome-icon v-if="chargement" :icon="['fal', 'spinner']" pulse/><br/>{{ $t('global.chargement') }} </p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import User from '@/mixins/User.js'

	export default {
		name: 'GocardlessRapprochement',
		mixins: [User],
		data () {
			return {
				email: '',
				error_message: '',
				chargement: false,
				token: null,
				rapprochement: null
			}
		},
		mounted: async function() {
			this.token = this.$route.params.token
			try {
				this.rapprochement = await this.getGocardlessRapprochement(this.token)
				if(!this.rapprochement) {
					throw ""
				}
			} catch (err) {
				this.$router.push({name: 'Home'})
			}
		},
		methods: {
			async submitLogs() {
				if(this.email != '') {
					this.chargement = true
					try {
						await this.saveGocardlessRapprochement(this.email, this.token)
						this.successToast()
						this.$router.push({name: 'Home'})
					} catch (err) {
						this.error_message = err.data.message
					}

					this.chargement = false
				}
			},
		},
	};
</script>
